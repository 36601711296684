import {
  useShippingProviderFactory,
  UseShippingProviderParams,
  Context,
} from '@vue-storefront/core';
import type {
  ShippingProvider,
  ShippingMethod,
} from '@propeller-commerce/propeller-api';

const params: UseShippingProviderParams<ShippingProvider, ShippingMethod> = {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  load: async (context: Context, { customQuery }) => {
    console.log('Mocked: loadShippingProvider');
    return {};
  },

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  save: async (context: Context, { shippingMethod, customQuery }) => {
    console.log('Mocked: saveShippingProvider');
    return {};
  },
};

export const useShippingProvider = useShippingProviderFactory<
  ShippingProvider,
  ShippingMethod
>(params);
